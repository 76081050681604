/* eslint-disable react/prop-types */
import { Box } from '@mantine/core'
import { DateInput } from '@mantine/dates'

const DateInputComp = ({
  label,
  value,
  setValue,
  placeholder,
  isRequired = 0,
  ...props
}) => {
  return (
    <Box>
      <DateInput
        label={label}
        valueFormat='DD-MMM-YYYY'
        placeholder={placeholder}
        value={value}
        withAsterisk={isRequired}
        clearable
        onChange={setValue}
        styles={{
          levelsGroup: {
            // fixed height to avoid calendar displacement...
            height: 300
          }
        }}
        {...props}
      />
    </Box>
  )
}

export default DateInputComp
