import { Anchor, Badge, Flex, Image, Text } from "@mantine/core";
import { IconExternalLink, IconEye } from "@tabler/icons-react";
import dayjs from "dayjs";

const DocumentLink = ({ link, alt }) => {
  return (
    <Anchor
      href={link}
      target="_blank"
      alt={alt}
      title={link}
      >
        <Badge size="sm" variant="light" leftSection={<IconEye size={12} />}>View</Badge>
    </Anchor>
  )
}

const valueTypesMap = new Map();
valueTypesMap.set('text', v => v ? v : ''+(v || ''));
valueTypesMap.set('date', v => v ? dayjs(v).format('DD-MMM-YY') : '-');
valueTypesMap.set('datetime', v => v ? dayjs(v).format('DD-MMM-YYYY hh:mm A') : '-');
// eslint-disable-next-line no-extra-boolean-cast
valueTypesMap.set('boolean', v => Boolean(v) ? <Badge size="sm" variant="light">Yes</Badge> : <Badge size="sm" variant="light" color="red">No</Badge>);
valueTypesMap.set('checkbox', v => Boolean(v) ? <Badge size="sm" variant="light">Yes</Badge> : <Badge size="sm" variant="light" color="red">No</Badge>);
valueTypesMap.set('time', v => v ? dayjs(`2001-01-01 ${v}`).format('HH:mm') : '-');
valueTypesMap.set('FORM_SEARCH', v => typeof v === 'string' ? v?.toUpperCase() : (v || ''+(v || '')));
valueTypesMap.set('image', v => typeof v === 'string' ? (<Image
  src={v}
  mah={30}
  alt="Image"
  radius="sm"
  fit="contain"
  fallbackSrc="https://placehold.co/45x30?text=Img"
  />) : (v || 'NA'));
valueTypesMap.set('document', v => typeof v === 'string' ? (<DocumentLink link={v} alt={"GRN Document"} />) : (v || 'NA'));

export const VALUE_TYPES = {
  text: 'text',
  date: 'date',
  amount: 'amount',
  datetime: 'datetime',
  time: 'time',
  boolean: 'boolean',
  image: 'image',
  document: 'document',
  checkbox: 'checkbox',
  // form_search: 'form_search',
};

export const changeValueFormat = (value, type = 'text', props = {}) => {
  const t = valueTypesMap.has(type) ? type : 'text';

  return valueTypesMap.get(t)(value, props);
}