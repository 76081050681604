export const COLORS = {
  primary: '#006B92',
  secondary: '#FFB400',
  blue: '#1971C2',
  darkBlue: '#1c7ed6',
  background: '#fdfff5',
  backgroundColors: {
    lightGray: '#f9f9f9',
    lightGreen: '#f7fffb',
  },
  white: 'white',
  gray: '#0000004D',
  textColor: {
    gray: 'gray',
    darkGray: '#00000099',
    table: '#555555',
    label: '#a9a9a9',
  },
  comp: {
    border: '#E4E4E4',
    label: '#888888',
  }
}