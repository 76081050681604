import {
  Grid,
  Text,
} from '@mantine/core'
import Body from '../../commonComponents/body/Body'
import Header from '../../commonComponents/layout/Header'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'

const Dashboard = () => {
  dayjs.extend(calendar) // use to load the plugins of dayjs
  dayjs().calendar(dayjs(new Date()))
  
  return (
    <>
      <Header title={'Welcome,'} />
      <Body>
        <Text fw={500}>In Progress</Text>
        <Grid gutter={'lg'}>
          
          <Text fw={500}>Please check Masters Page for the current progress.</Text>
        </Grid>
      </Body>
    </>
  )
}

export default Dashboard
