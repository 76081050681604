import {
  downloadAPICall,
  getAPICall,
  patchAPICall,
  postAPICall
} from './apiCall'

export const getSelectOptionsByTableName = ({ tableName, filters, search }) => {
  let url = `items/${tableName}`
  let options = []
  if (filters) options.push(`filters=${filters}`)
  if (search) options.push(`search=${search}`)
  if (options.length) url += `?${options.join('&')}`
  return getAPICall(url)
}

export const fileUploadAPI = ({ body, tableName = 'others' }) => {
  return postAPICall(`document-upload/${tableName}`, {
    body,
    headers: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const bulkUploadAPI = ({
  body,
  tableName = 'others',
  returnErrorObject = false
}) => {
  return postAPICall(`upload/${tableName}`, {
    body,
    headers: { headers: { 'Content-Type': 'multipart/form-data' } },
    returnErrorObject
  })
}

export const getFileTemplate = async ({ tableName = 'users', fileName }) => {
  return downloadAPICall(`template/${tableName}`, { fileName })
}

export const exportTableDetails = ({
  source,
  search,
  type,
  filter,
  sort,
  fileType = 'xlsx'
}) => {
  let url = `download/${source}?file_type=${fileType}`
  if (search) url += `&search=${search}`
  if (filter && Object.entries(filter)?.filter(([_, value]) => value)?.length) {
    url += `&filters={${Object.entries(filter)
      .map(([key, value]) => `"${key}_eq":${value}`)
      .join(',')}}`
  }
  // id, desc
  if (
    (!filter ||
      !Object.entries(filter)?.filter(([_, value]) => value)?.length) &&
    type
  )
    url += `&filters={"status_in":[${type}]}`
  if (sort?.[0]) {
    url += `&sort_by=${sort.map(d => (d.desc ? `-${d.id}` : d.id)).join(',')}`
  }
  return getAPICall(url, { returnObject: true })
}

export const getAllData = ({
  source,
  page,
  search,
  type,
  fileType,
  filter,
  sort
}) => {
  let url = `items/${source}?page=${fileType ? -1 : page}`
  if (search) url += `&search=${search}`
  if (filter && Object.entries(filter)?.filter(([_, value]) => value)?.length) {
    url += `&filters={${Object.entries(filter)
      .map(([key, value]) => `"${key}_eq":${value}`)
      .join(',')}}`
  }
  if (fileType) url += `&download_file_type=${fileType}`
  // id, desc
  if (
    (!filter ||
      !Object.entries(filter)?.filter(([_, value]) => value)?.length) &&
    type
  )
    url += `&filters={"status_in":[${type}]}`
  if (sort?.[0]) {
    url += `&sort_by=${sort.map(d => (d.desc ? `-${d.id}` : d.id)).join(',')}`
  }
  return getAPICall(url, { returnObject: true })
}

export const getAllDataWithFilters = ({
  source,
  page,
  search,
  type,
  fileType,
  filter,
  sort
}) => {
  let url = `items/${source}?page=${fileType ? -1 : page}`
  if (search) url += `&search=${search}`
  if (filter && Object.entries(filter)?.filter(([_, value]) => value)?.length) {
    // url += `&filters={${Object.entries(filter).map(([key, value]) => `"${key}_eq":${value}`).join(",")}}`;
    url += `&filters={${filter
      .map(
        ({ key, value, condition = 'eq' }) => `"${key}${condition ? `_${condition}` : ''}":${Array.isArray(value) ? JSON.stringify(value) : '"'+value+'"'}`
      )
      .join(',')}}`
  }
  if (fileType) url += `&download_file_type=${fileType}`
  // id, desc
  // if ((!filter || !Object.entries(filter)?.filter(([_, value]) => value)?.length) && type) url += `&filters={"status_in":[${type}]}`;
  if (sort?.[0]) {
    url += `&sort_by=${sort.map(d => (d.desc ? `-${d.id}` : d.id)).join(',')}`
  }
  return getAPICall(url, { returnObject: true })
}

export const getAllDataV2 = ({
  source,
  page,
  per_page = 10,
  summary,
  search,
  fileType,
  filter,
  sort,
  download
}) => {
  let url = `query/${source}?page=${fileType ? -1 : page}&per_page=${per_page}`
  if (search) url += `&search=${search}`
  if (summary) url += `&summary=${summary?.join(',')}`
  if (Array.isArray(filter) && filter.length) {
    url += `&filters=${filter
      .map(({ key, value, condition = '=' }) => `${key}${condition}'${value}'`)
      .join('_and_')}`
  }
  if (fileType) url += `&download_file_type=${fileType}`
  if (download) url += `&export_as_file=true`
  // id, desc
  if (Array.isArray(sort) && sort.length) {
    url += `&sort_by=${sort
      .map(d => (d.desc ? `${d.id} desc` : d.id))
      .join(',')}`
  }
  return getAPICall(url, { returnObject: true })
}

export const getReportData = ({
  source,
  page,
  per_page = 10,
  summary,
  search,
  fileType,
  filter,
  sort,
  download
}) => {
  let url = `reports/${source}?page=${
    fileType ? -1 : page
  }&per_page=${per_page}`
  if (search) url += `&search=${search}`
  if (summary) url += `&summary=${summary?.join(',')}`
  if (Array.isArray(filter) && filter.length) {
    url += `&filters={${filter
      .map(
        ({ field, value }) =>
          `"${field}":${
            Array.isArray(value) ? '[' + value + ']' : '"' + value + '"'
          }`
      )
      .join(',')}}`
  }
  if (fileType) url += `&download_file_type=${fileType}`
  if (download) url += `&export_as_file=true`
  // id, desc
  if (Array.isArray(sort) && sort.length) {
    url += `&sort_by=${sort
      .map(d => (d.desc ? `${d.id} desc` : d.id))
      .join(',')}`
  }
  return getAPICall(url, { returnObject: true })
}

export const getDataById = ({ id, source }) => {
  let url = `items/${source}/${id}`
  return getAPICall(url, { returnFirstItem: true })
}

export const getHeaders = ({ source }) => {
  return getAPICall(`fields/${source}?action=read&sort_by=sort`)
}

export const createNewRecord = ({ source, body }) => {
  return postAPICall(`items/${source}`, { body })
}

export const updateRecord = ({ source, body }) => {
  return patchAPICall(`items/${source}/${body.id}`, { body })
}

export const bulkUpsert = ({ source, body }) => {
  return postAPICall(`bulk-upsert`, {
    body: {
      [source]: body
    }
  })
}
