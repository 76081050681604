import { getAPICall, postAPICall } from "./apiCall"


export const getAllUsers = ({ page, search, type, fileType, filter }) => {
  let url = `items/users?page=${fileType ? -1 : page}`;
  if (search) url += `&search=${search}`;
  if (filter && Object.entries(filter)?.filter(([_, value]) => value)?.length) type ? url += `&filters={${Object.entries(filter)?.filter(([_, value]) => value).map(([key, value]) => `"${key}_eq":"${value}"`).join(",")},"status_in":[${type}]}` : `&filters={${Object.entries(filter).map(([key, value]) => `"${key}_eq":${value}`).join(",")}}`;
  if (fileType) url += `&download_file_type=${fileType}`;
  if ((!filter || !Object.entries(filter)?.filter(([_, value]) => value)?.length) && type) url += `&filters={"status_in":[${type}]}`;
  return getAPICall(url, { returnObject: true })
}

export const getCabinUsers = ({ page, search, type, fileType, filter }) => {
  let url = `items/users?page=${fileType ? -1 : page}`;
  if (search) url += `&search=${search}`;
  if (filter && Object.entries(filter)?.filter(([_, value]) => value)?.length) type ? url += `&filters={${Object.entries(filter)?.filter(([_, value]) => value).map(([key, value]) => `"${key}_eq":"${value}"`).join(",")},"status_in":[${type}]}` : `&filters={${Object.entries(filter).map(([key, value]) => `"${key}_eq":${value}`).join(",")}}`;
  if (fileType) url += `&download_file_type=${fileType}`;
  if (!filter) url += `&filters={"emp_role_id.role_name_in":["SCC","CC"]}`;
  return getAPICall(url, { returnObject: true })
}

export const getUserById = ({ id }) => {
  let url = `items/users/${id}`;
  return getAPICall(url, { returnFirstItem: true })
}

export const getHeaders = () => {
  return getAPICall('fields/users?action=read')
}


export const createNewUser = ({ body }) => {
  return postAPICall('create-user', { body })
}
