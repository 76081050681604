import { Route, Routes } from 'react-router-dom'
import Layout from './commonComponents/layout/Layout'
import NotFound from './pages/notFound/NotFound'
import MainLayout from './commonComponents/layout/MainLayout'
import { useNetwork } from '@mantine/hooks'
import { displayNotification } from './commonComponents/notifications/displayNotification'
import { updatedNotification } from './commonComponents/notifications/updateNotification'
import Dashboard from './pages/dashboard/Dashboard'
import Employees from './pages/masters/employees'
import Aircraft from './pages/masters/aircrafts'
import Airports from './pages/masters/airports'
import Products from './pages/masters/products'
import ProductCategories from './pages/masters/product-categories'
import MastersLayout from './commonComponents/layout/MastersLayout'
import Roles from './pages/masters/roles'
import Groups from './pages/masters/groups'
import Journeys from './pages/journey/journey'
import GRN from './pages/grn/grn'
import ReturnReasons from './pages/masters/return-reasons'
import ReturnProduct from './pages/masters/return-product'
import Retrievals from './pages/retrieval/retrieval'
import Reports from './pages/reports/reports'
import ReportPage from './pages/reports/reportPage'
import PaymentExceptionReasons from './pages/masters/payment-exception-reasons'

const Routing = () => {
  const network = useNetwork()

  if (!network.online) {
    displayNotification({
      id: 'offline',
      loading: true,
      description: "You're offline",
      message: 'Oops! You are disconnected from Internet',
      variant: 'error',
      autoClose: false
    })
  } else {
     updatedNotification({
      id: 'offline',
      loading: false,
      description: "You're back online",
      message: 'Hurray! You are connected back to Internet',
      variant: 'success',
      autoClose: true
    })
  }

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route element={<MainLayout />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='masters' element={<MastersLayout />}>
            <Route path='' element={<Aircraft />} />
            <Route path='aircraft' element={<Aircraft />} />
            <Route path='airports' element={<Airports />} />
            <Route path='employees' element={<Employees />} />
            <Route path='roles' element={<Roles />} />
            <Route path='groups' element={<Groups />} />
            <Route path='product-categories' element={<ProductCategories />} />
            <Route path='return-reasons' element={<ReturnReasons />} />
            <Route path='payment-exception-reasons' element={<PaymentExceptionReasons />} />
          </Route>
          <Route path='products' element={<Products />} />
          <Route path='return-product' element={<ReturnProduct />} />
          <Route path='journeys' element={<Journeys />} />
          {/* <Route path='orders' element={<Orders />} /> */}
          <Route path='retrievals' element={<Retrievals />} />
          <Route path='grn' element={<GRN />} />
          <Route path='reports' element={<Reports />} />
          <Route path='reports/:report' element={<ReportPage />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default Routing
