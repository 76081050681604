import { create } from 'zustand'

const useGRNStore = create(
  set => ({
    grn: {
      grn_entry_date: new Date()
    },
    grnErrors: {},
    setGRN: (obj) => set({ grn: { ...obj } }),
    setGRNErrors: (obj) => set({ grnErrors: { ...obj } }),
    reset: () => set({
        grn: {
          grn_entry_date: new Date()
        },
        grnErrors: {}
      })
  })
)
export const useGRNProductsStore = create(
  (set, get) => ({
    products: [{}],
    productErrors: [],
    setProducts: (i, obj) => {
      const products = get().products
      if (i !== undefined) products[i] = obj
      else products.push(obj)
      set({ products })
    },
    setProductErrors: (errList) => {
      set({ productErrors: errList })
    },
    addNewProduct: () => {
      const products = get().products
      products.push({})
      set({ products })
    },
    removeProduct: (i) => {
      const products = get().products
      products.splice(i, 1)
      set({ products })
    },
    reset: () => {
      set({ products: [{}], productErrors: [] })
    }
  })
)

export const checkGrnErrors = () => {
  let hasGrnErrors = false, hasProductErrors = false;
  const grnData = useGRNStore.getState().grn;
  const productData = useGRNProductsStore.getState().products;
  const grnErrors = {}, productErrors=(new Array(productData.length)).fill({});
  if (!grnData.grn_entry_date) {
    hasGrnErrors = true;
    grnErrors.grn_entry_date = 'Choose a valid date';
  }
  if (!grnData.document_link) {
    hasGrnErrors = true;
    grnErrors.document_link = 'Choose a valid GRN Document';
  }

  productData.forEach((productItem, index) => {
    if (!productItem.product_id) {
      hasProductErrors = true;
      productErrors[index].product_id = 'Choose a product';
    }
    if (!productItem.quantity_received) {
      hasProductErrors = true;
      productErrors[index].quantity_received = 'Enter quantity';
    }
    if (!productItem.valid_from) {
      hasProductErrors = true;
      productErrors[index].valid_from = 'Choose a valid date';
    }
    if (!productItem.valid_till) {
      hasProductErrors = true;
      productErrors[index].valid_till = 'Choose a valid date';
    }
    if (!productItem.po) {
      hasProductErrors = true;
      productErrors[index].po = 'Enter PO';
    }
  });

  if (hasGrnErrors) {
    useGRNStore.getState().setGRNErrors(grnErrors);
  }

  if (hasProductErrors) {
    useGRNProductsStore.getState().setProductErrors(productErrors);
  }
  
  return hasGrnErrors || hasProductErrors;
}

export const getFormattedGrnDataForInsert = () => {
  const grnData = useGRNStore.getState().grn;
  const productData = useGRNProductsStore.getState().products;

  return {
    ...grnData,
    grn_products: productData
  }
}

export const clearGrnStore = () => {
  useGRNStore.getState().reset();
  useGRNProductsStore.getState().reset();
}

export const clearGrnErrors = () => {
  useGRNStore.getState().setGRNErrors({});
  useGRNProductsStore.getState().setProductErrors([]);
}

export default useGRNStore;