import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { displayNotification } from '../../commonComponents/notifications/displayNotification'

import Body from '../../commonComponents/body/Body'
import ReactTable from '../../commonComponents/reactTable/ReactTable'
import { generateReportHeaders } from '../../utils/table.utils'
import { createNewRecord, fileUploadAPI, getAllData, getHeaders, updateRecord } from '../../services/common.service'
import ActionButton from '../../commonComponents/components/actionButton/ActionButton'
import EditModal from '../../commonComponents/components/editModal/EditModal'

const source = 'user_roles';
let defaultSorting = { id: 'role_name', desc: false };
const Roles = () => {
  const [pagination, setPagination] = useState({ page: 1, search: '', source, sort: [defaultSorting] })
  const [editModal, setEditModal] = useState()

  const allRecordsQuery = useQuery({
    queryKey: ['all-records', source, pagination],
    queryFn: () => getAllData(pagination)
  })

  const tableHeadersQuery = useQuery({
    queryKey: ['table-headers', source],
    queryFn: () => getHeaders({ source }),
    select: data => {
      return { headers: generateReportHeaders(data?.[0]), data: data?.[0] }
    }
  })

  const uploadDataQuery = useMutation({
    mutationFn: body => fileUploadAPI({ body, tableName: source })
  })

  const createDataQuery = useMutation({
    mutationFn: body => createNewRecord({ body, source })
  })

  const updateDataQuery = useMutation({
    mutationFn: body => updateRecord({ body, source })
  })

  useEffect(() => {
    if (pagination?.search) {
      setPagination(old => ({ ...old, page: 1 }))
    }
  }, [pagination?.search])

  const handleSorting = e => {
    // find the sorting data in the array from previous state
    const d = e(pagination.sort);
    setPagination(old => ({ ...old, sort: d }));
  }

  return (
    <>
      <Body>
        <ActionButton
          title={'Employee Roles'}
          builderTitle={'Add New Role'}
          pagination={pagination}
          setPagination={setPagination}
          actionName={source}
          saveLoading={createDataQuery?.isPending}
          tableName={source}
          onSave={(e, state) =>
            createDataQuery?.mutate(e?.data, {
              onSuccess: () => {
                displayNotification({
                  message: 'New Role Added Successfully',
                  variant: 'success'
                })
                state({})
                allRecordsQuery?.refetch()
              },
              onError: err => {
                displayNotification({
                  message: err?.message || err,
                  variant: 'error'
                })
              }
            })
          }
          builderData={tableHeadersQuery?.data?.data}
          uploadDataQuery={uploadDataQuery}
          queryKey={'roles-list'}
        />
        <ReactTable
          sorting={pagination.sort}
          setSorting={handleSorting}
          columnData={tableHeadersQuery?.data?.headers}
          loading={
            allRecordsQuery?.isLoading || tableHeadersQuery?.isLoading
          }
          onRowClick={e => setEditModal({ data:e, modal: true})}
          totalNoOfPages={
            allRecordsQuery?.data?.metadata?.total_number_of_page
          }
          totalNoOfRecords={allRecordsQuery?.data?.metadata?.records}
          rowData={allRecordsQuery?.data?.data}
          page={pagination?.page}
          setPage={page => setPagination({ ...pagination, page })}
        />

        <EditModal
          size='md'
          formData={editModal?.data}
          opened={editModal?.modal}
          onClose={() => setEditModal({ modal: false })}
          builderTitle={'Edit Role'}
          saveLoading={updateDataQuery?.isPending}
          tableName={source}
          onSave={(e) =>
            updateDataQuery?.mutate(e, {
              onSuccess: () => {
                displayNotification({
                  message: 'Role Updated Successfully',
                  variant: 'success'
                })
                setEditModal({ data:{}, modal: false})
                allRecordsQuery?.refetch()
              },
              onError: err => {
                displayNotification({
                  message: err?.message || err,
                  variant: 'error'
                })
              }
            })
          }
        />
      </Body>
    </>
  )
}

export default Roles
