/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { ActionIcon, Box, Button, Flex, Grid, Group, ScrollArea, Table } from '@mantine/core'
import { IconCalendar, IconCheck, IconPlus, IconTrash } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import ModalComp from '../../commonComponents/components/modal/ModalComp'
import { getAllData } from '../../services/common.service'
import { saveNewGRN } from '../../services/grn.service'
import { displayNotification } from '../../commonComponents/notifications/displayNotification'
import { FormBuilderFields } from '../../commonComponents/formBuilder/FormBuilderFields'
import useGRNStore, { checkGrnErrors, clearGrnStore, getFormattedGrnDataForInsert, useGRNProductsStore } from '../../store/grnStore'
import SelectComp from '../../commonComponents/components/select/SelectComp'
import NumberInputComp from '../../commonComponents/components/numberInput/NumberInputComp'
import dayjs from 'dayjs'
import DateInputComp from '../../commonComponents/components/dateInput/DateInputComp'
import TextInputComp from '../../commonComponents/components/textInput/TextInputComp'
import FileUploadInput from '../../commonComponents/components/fileUploadInput/FileUploadInput'

const allFields = {
  grn_entry_date: {
    label: 'GRN Date',
    field: 'grn_entry_date',
    interface: 'DATE',
    is_required: true,
    placeholder: 'Select GRN Date'
  },
  product_id: {
    label: 'Product',
    field: 'product_id',
    interface: 'FORM_SEARCH',
    is_required: true,
  },
  quantity_received: {
    label: 'Quantity',
    field: 'quantity_received',
    interface: 'NUMERIC',
    is_required: true,
  },
  valid_from: {
    label: 'Quantity',
    field: 'valid_from',
    interface: 'DATE',
    is_required: true,
  },
  valid_till: {
    label: 'Quantity',
    field: 'valid_till',
    interface: 'DATE',
    is_required: true,
  },
  mrp: {
    label: 'MRP',
    field: 'mrp',
    interface: 'NUMERIC',
    is_required: true,
  },
  po: {
    label: 'PO',
    field: 'po',
    interface: 'TEXT',
    is_required: true,
  },
  document_link: {
    label: 'Document',
    field: 'document_link',
    interface: 'DOCUMENT',
    is_required: true,
    placeholder: 'Upload GRN Document'
  },
}

const GrnProducts = () => {
  const { products, setProducts, productErrors, addNewProduct, removeProduct } = useGRNProductsStore(state => state);

  const getProductsQuery = useQuery({
    queryKey: ['all-products'],
    queryFn: () => getAllData({ source: 'products', page: -1 }),
    select: data => {
      return data?.data?.map(items => {
        return {
          label: `${items?.product_name} (${items?.product_code})`,
          value: `${items?.id}`
        }
      })
    }
  })

  const onChangeValue = (index, field, val) => {
    const item = products?.[index];
    setProducts(index, {
      ...item,
      [field]: val,
    })
  }

  return (
    <Box>
      <Table verticalSpacing={8} horizontalSpacing={4}>
        <Table.Thead>
          <Table.Tr>
            <Table.Td c='gray.6'>#</Table.Td>
            <Table.Td>Product</Table.Td>
            <Table.Td>Quantity</Table.Td>
            <Table.Td>Valid From</Table.Td>
            <Table.Td>Valid Till</Table.Td>
            <Table.Td>PO</Table.Td>
            <Table.Td></Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {
            products?.map((item, index) => {
              return (
                <Table.Tr key={index}>
                  <Table.Td c='gray.6'>
                    {index+1}
                  </Table.Td>
                  <Table.Td>
                    <SelectComp
                      isRequired
                      value={item?.product_id}
                      error={productErrors?.[index]?.product_id}
                      placeholder={'Choose Product'}
                      setValue={e => onChangeValue(index, 'product_id', e)}
                      options={getProductsQuery.data || []}
                    />
                  </Table.Td>
                  <Table.Td>
                    <NumberInputComp
                      w={80}
                      isRequired
                      value={item?.quantity_received}
                      error={productErrors?.[index]?.quantity_received}
                      setValue={e => onChangeValue(index, 'quantity_received', e)}
                    />
                  </Table.Td>
                  <Table.Td>
                    <DateInputComp
                      w={140}
                      isRequired
                      maxDate={new Date()}
                      value={item?.valid_from ? new Date(item?.valid_from) : null}
                      error={productErrors?.[index]?.valid_from}
                      rightSection={<IconCalendar size={16} />}
                      setValue={e => {
                        onChangeValue(index, 'valid_from', e ? dayjs(e).format('YYYY-MM-DD') : null)
                      }}
                    />
                  </Table.Td>
                  <Table.Td>
                    <DateInputComp
                      w={140}
                      isRequired
                      value={item?.valid_till ? new Date(item?.valid_till) : null}
                      error={productErrors?.[index]?.valid_till}
                      minDate={item?.valid_from ? new Date(item?.valid_from) : new Date()}
                      rightSection={<IconCalendar size={16} />}
                      setValue={e => {
                        onChangeValue(index, 'valid_till', e ? dayjs(e).format('YYYY-MM-DD') : null)
                      }}
                    />
                  </Table.Td>
                  <Table.Td>
                    <TextInputComp
                      isRequired
                      value={item?.po}
                      error={productErrors?.[index]?.po}
                      setValue={e => onChangeValue(index, 'po', e)}
                    />
                  </Table.Td>
                  <Table.Td>
                    <ActionIcon
                      display={index === 0 ? 'none' : 'block'}
                      color='red'
                      variant='subtle'
                      onClick={() => removeProduct(index)}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Table.Td>
                </Table.Tr>
              )
            })
          }
        </Table.Tbody>
      </Table>
      <Flex justify='center'>
        <Button
          mt='xs'
          size='compact-sm'
          variant='outline'
          onClick={addNewProduct}
          leftSection={<IconPlus size={14} />}
          >
          Add Product
        </Button>
      </Flex>
    </Box>
  )
}

const NewGRNForm = ({
  opened,
  size='xl',
  onClose,
  onSuccess,
}) => {
  const grnState = useGRNStore(state => state)

  const saveGrnMutation = useMutation({
    mutationFn: body => saveNewGRN({ grn_entries: body }),
    onSuccess: () => {
      displayNotification({
        message: 'GRN created successfully',
        variant: 'success',
      })
      clearGrnStore();
      onSuccess()
    },
    onError: () => {
      displayNotification({
        message: 'Something went wrong',
        variant: 'error',
      })
    },
  })

  function handleSave() {
    if (!checkGrnErrors()){
      const reqBody = getFormattedGrnDataForInsert();
      saveGrnMutation.mutate(reqBody)
    }
  }

  return (
    <ModalComp
      opened={opened}
      closeOnClickOutside={false}
      onClose={onClose}
      size={size}
      radius={'md'}
      title={'New GRN Entry'}
    >
      <ScrollArea.Autosize
        mah={'85vh'}
        mih={180}
        offsetScrollbars
      >
        <Grid>
          <Grid.Col span={4}>
            <DateInputComp
              isRequired
              label={allFields.grn_entry_date?.label}
              value={grnState?.grn?.grn_entry_date ? new Date(grnState?.grn?.grn_entry_date) : null}
              rightSection={<IconCalendar size={16} />}
              placeholder={allFields.grn_entry_date?.placeholder}
              error={grnState.grnErrors?.grn_entry_date}
              minDate={dayjs(new Date()).subtract(2, 'day').toDate()}
              maxDate={new Date()}
              setValue={e => {
                grnState.setGRN({
                  ...grnState?.grn,
                  grn_entry_date: e ? dayjs(e).format('YYYY-MM-DD') : null
                })
              }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <FileUploadInput
              isRequired
              label={allFields?.document_link?.label}
              value={grnState?.grn?.document_link}
              tableName={'grn'}
              error={grnState.grnErrors?.document_link}
              placeholder={allFields?.document_link?.placeholder}
              setValue={(v, obj) =>{
                grnState.setGRN({
                  ...grnState?.grn,
                  document_link: v
                })
              }}
            />
          </Grid.Col>
        </Grid>
        <GrnProducts />
      </ScrollArea.Autosize>
      <Grid>
        <Grid.Col span={12}>
          <Group justify='flex-end' mt={'md'} mx={'md'}>
            <Group>
              <Button
                w={200}
                color='gray'
                variant='light'
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                w={200}
                leftSection={<IconCheck size={16} />}
                onClick={handleSave}
                loading={saveGrnMutation.isLoading}
              >
                Save
              </Button>
            </Group>
          </Group>
        </Grid.Col>
      </Grid>
    </ModalComp>
  )
}

export default NewGRNForm
