/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { ActionIcon, Alert, Box, Button, Drawer, Flex, Grid, Group, ScrollArea, Table, Text, TextInput, Title } from '@mantine/core'
import { IconAlertTriangle, IconCalendar, IconCheck, IconPlus, IconTrash, IconX } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getAllData, getAllDataWithFilters } from '../../services/common.service'
import { displayNotification } from '../../commonComponents/notifications/displayNotification'
import SelectComp from '../../commonComponents/components/select/SelectComp'
import dayjs from 'dayjs'
import DateInputComp from '../../commonComponents/components/dateInput/DateInputComp'
import TextInputComp from '../../commonComponents/components/textInput/TextInputComp'
import { checkNewJourneyErrors, clearJourneyStore, getFormattedJourneyDataForInsert, useJourneyLegInventoryStore, useJourneyLegsStore, useJourneyStore, useSectorStore } from '../../store/journeyStore'
import { getCabinUsers } from '../../services/employees.service'
import { saveNewJourney } from '../../services/journey.service'
import TimeInputComp from '../../commonComponents/components/dateInput/TimeInputComp'
import NumberInputComp from '../../commonComponents/components/numberInput/NumberInputComp'

const LegData = ({
  airportsQuery,
  usersQuery,
  position,
  sccUsers,
  ccUsers,
}) => {
  const { legs, setLegsByIndex, legErrors, addNewLeg, removeLeg } = useJourneyLegsStore(state => state);
  const sectorState = useSectorStore(state => state);
  const index = position - 1;
  const curLeg = legs?.[index];
  
  if (!curLeg) {
    if (legs.length + 1 === position) {
      return (
        <Table.Tr>
          <Table.Td colSpan={7}>
            <Flex justify={'center'}>
              <Button
                variant='light'
                onClick={addNewLeg}
                leftSection={<IconPlus size={14} />}
              >Add Leg</Button>
            </Flex>
          </Table.Td>
        </Table.Tr>
      )
    }
    return null
  }


  const handleChange = (field, val) => {
    const item = legs?.[index];
    curLeg[field] = val;
    setLegsByIndex(index, item);
  }

  const toAirports = airportsQuery.data?.filter(
    item => item?.value !== curLeg?.depart_airport_id
  );

  return (
    <Table.Tr>
      <Table.Td>{position}</Table.Td>
      <Table.Td>
        <TextInputComp
          isRequired
          placeholder={'Flight no'}
          value={curLeg?.flight_number}
          error={legErrors?.[index]?.flight_number}
          setValue={e => handleChange('flight_number', e?.toUpperCase())}
        />
      </Table.Td>
      <Table.Td>
        <TimeInputComp
          isRequired
          showPicker
          value={curLeg?.departure_time}
          error={legErrors?.[index]?.departure_time}
          setValue={e => {
            handleChange('departure_time', e?.target?.value)
          }}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          placeholder={'From'}
          data={airportsQuery.data}
          error={legErrors?.[index]?.depart_airport_id}
          value={curLeg?.depart_airport_id}
          setValue={(val, _option) => {
            handleChange('depart_airport_id', val)
            let s = {
              from: _option.airport_code
            };
            if (sectorState.sector[index]) {
              s.to = sectorState.sector[index]?.to;
            }
            sectorState.setSector(index, s)
          }}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          placeholder={'To'}
          data={toAirports}
          error={legErrors?.[index]?.arrive_airport_id}
          value={curLeg?.arrive_airport_id}
          setValue={(val, _option) => {
            handleChange('arrive_airport_id', val)
            let s = {
              to: _option.airport_code
            };
            if (sectorState.sector[index]) {
              s.from = sectorState.sector[index]?.from;
            }
            sectorState.setSector(index, s)
          }}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          placeholder={'SCC'}
          data={sccUsers || []}
          error={legErrors?.[index]?.senior_cabin_crew_id}
          value={curLeg?.senior_cabin_crew_id}
          setValue={val => handleChange('senior_cabin_crew_id', val)}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          placeholder={'CC'}
          data={ccUsers || []}
          error={legErrors?.[index]?.cabin_crew_id}
          value={curLeg?.cabin_crew_id}
          setValue={val => handleChange('cabin_crew_id', val)}
        />
      </Table.Td>
      <Table.Td>
        {
          position > 1 && position === legs.length ? (
            <ActionIcon
              variant={'subtle'}
              color={'red'}
              onClick={() => {
                removeLeg(index);
                sectorState.removeSector();
              }}
            >
              <IconTrash size={16} />
            </ActionIcon>
          ) : (
            <ActionIcon
              variant={'transparent'}
              onClick={() => null}
            />
          )
        }
      </Table.Td>
    </Table.Tr>
  )
}

const JourneyLegs = () => {
  const airportsQuery = useQuery({
    queryKey: ['all-airports'],
    queryFn: () => getAllData({ source: 'airports', page: -1, filter: { is_active: true } }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: items?.airport_code,
          value: `${items?.id}`
        }
      })
    }
  })
  const usersQuery = useQuery({
    queryKey: ['all-cabin-users'],
    queryFn: () => getCabinUsers({ page: -1 }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: `${items?.emp_name} (${items?.emp_code})`,
          value: `${items?.id}`,
        }
      })
    }
  })

  const sccUsers = usersQuery.data?.filter(item => item?.emp_role_id?.role_name === "SCC");
  const ccUsers = usersQuery.data?.filter(item => item?.emp_role_id?.role_name === "CC");

  return (
    <Box>
      <Table verticalSpacing={8} horizontalSpacing={4}>
        <Table.Thead>
          <Table.Tr c='gray.6'>
            <Table.Td>#</Table.Td>
            <Table.Td align='center' w={'16%'}>Flight No</Table.Td>
            <Table.Td align='center' w={'16%'}>Dep. Time</Table.Td>
            <Table.Td align='center' w={'16%'}>From</Table.Td>
            <Table.Td align='center' w={'16%'}>To</Table.Td>
            <Table.Td align='center' w={'16%'}>SCC</Table.Td>
            <Table.Td align='center' w={'16%'}>CC</Table.Td>
            <Table.Td align='center'></Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <LegData airportsQuery={airportsQuery} usersQuery={usersQuery} sccUsers={sccUsers} ccUsers={ccUsers} position={1} />
          <LegData airportsQuery={airportsQuery} usersQuery={usersQuery} sccUsers={sccUsers} ccUsers={ccUsers} position={2} />
          <LegData airportsQuery={airportsQuery} usersQuery={usersQuery} sccUsers={sccUsers} ccUsers={ccUsers} position={3} />
          <LegData airportsQuery={airportsQuery} usersQuery={usersQuery} sccUsers={sccUsers} ccUsers={ccUsers} position={4} />
          <LegData airportsQuery={airportsQuery} usersQuery={usersQuery} sccUsers={sccUsers} ccUsers={ccUsers} position={5} />
        </Table.Tbody>
      </Table>
    </Box>
  )
}

const ProductListing = ({journeyDate}) => {

  const productFilters = [
    { key: 'is_active', value: true, condition: 'eq' },
    { key: 'effective_from', value: journeyDate, condition: 'lte' },
    { key: 'or', condition: '', value: [{"effective_till_gte":journeyDate, "effective_till_null":true}] }
  ];
  
  const { inventory, inventoryError, setInventory, setInventoryItem } = useJourneyLegInventoryStore(state => state);
  const productsQuery = useQuery({
    queryKey: ['all-products',productFilters],
    queryFn: () => getAllDataWithFilters({ source: 'products', page: -1, filter: productFilters, sort: [{ id: 'product_name', desc: false }] }),
  });

  useEffect(() => {
    if (productsQuery.data?.data) {
      const d = productsQuery.data?.data?.map(item => {
        let opening = item.default_uplift_count || 0;
        if (item.available_quantity < item.default_uplift_count) {
          opening = item.available_quantity <= 0 ? 0 : item.available_quantity;
        }
        return {
          ...item,
          opening
        }
      })
      setInventory(d);
    }
  }, [productsQuery.data]);

  const handleChange = (e, item) => {
    item.opening = e;
    setInventoryItem(item);
  }

  const clearInventoryItems = () => {
    const inv = inventory.map(item => {
       return {
          ...item,
          opening: 0
       }
    })
    setInventory(inv)
  }

  return (
    <Box>
      {
        inventoryError ? (
          <Alert color={'red'} icon={<IconAlertTriangle size={16} />}>
            Kindly add opening for a product
          </Alert>
        ) : null
      }
      <Table>
        <Table.Thead>
          <Table.Tr c='gray.6'>
            <Table.Td>Items (for Leg #1)</Table.Td>
            <Table.Td align='right'>Available</Table.Td>
            <Table.Td>
              <Flex direction={'row'} align={'center'} justify={'space-around'}>
                <Box>Uplift</Box>
                <ActionIcon
                  variant={'subtle'}
                  color={'red'}
                  size={'compact-xs'}
                  title='Clear All'
                  onClick={clearInventoryItems}
                >
                  <IconX size={16} />
                </ActionIcon>
              </Flex>
            </Table.Td>
            <Table.Td></Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {
            inventory?.map((item, index) => (
              <Table.Tr key={item.product_name}>
                <Table.Td>{item.product_name}</Table.Td>
                <Table.Td align='right'>{item.available_quantity}</Table.Td>
                <Table.Td>
                  <Flex direction={'row'} align={'center'}>
                    <NumberInputComp
                      w={80}
                      min={0}
                      isRequired
                      disabled={item.available_quantity <= 0}
                      hideControls={false}
                      withKeyboardEvents
                      max={item.available_quantity <= 0 ? 0 : item.available_quantity}
                      clampBehavior="strict"
                      value={item?.opening}
                      styles={{ input: { textAlign: 'center' } }}
                      setValue={e => handleChange(e, item)}
                    />
                    {
                      item.opening > 0 ? (
                        <ActionIcon
                          variant={'subtle'}
                          color={'red'}
                          onClick={() => handleChange(0, item)}
                        >
                          <IconX size={16} />
                        </ActionIcon>
                      ) : null
                    }

                  </Flex>
                </Table.Td>
                <Table.Td align='left'>
                </Table.Td>
              </Table.Tr>
            ))
          }
        </Table.Tbody>
      </Table>
    </Box>
  )
}

const NewJourneyForm = ({
  opened,
  size='75%',
  onClose,
  onSuccess,
}) => {
  const journeyState = useJourneyStore(state => state)
  const sectorPattern = useSectorStore(state => state.pattern)

  const aircraftQuery = useQuery({
    enabled: opened,
    queryKey: ['all-aircrafts'],
    queryFn: () => getAllData({ source: 'aircrafts', page: -1, filter: { is_active: true } }),
    select: data => {
      return data?.data?.map(items => {
        return {
          label: items?.tail_reg_no,
          value: `${items?.id}`
        }
      })
    }
  })

  const saveJourneyMutation = useMutation({
    mutationFn: body => saveNewJourney({ journey: body }),
    onSuccess: () => {
      displayNotification({
        message: 'Journey added successfully',
        variant: 'success',
      })
      clearJourneyStore();
      onSuccess()
    },
    onError: (e) => {
      console.log(e);
      displayNotification({
        message: e.message || 'Something went wrong',
        variant: 'error',
      })
    },
  })

  function handleSave() {
    if (!checkNewJourneyErrors()){
      const reqBody = getFormattedJourneyDataForInsert();
      saveJourneyMutation.mutate(reqBody)
      // console.log(reqBody);
    }
  }

  const handleClose = () => {
    clearJourneyStore();
    onClose();
  }

  return (
    <Drawer
      opened={opened}
      onClose={handleClose}
      title={<Title size={'md'} fw={600}>New Journey</Title>}
      padding='md'
      size={size}
      position='right'
      radius="md"
      offset={8}
      overlayProps={{ backgroundOpacity: 0.5, blur: 1 }}
    >
      <Flex direction={'column'} gap={4}>
        <Box flex={1}>

          <Grid>
            <Grid.Col span={3}>
              <DateInputComp
                isRequired
                label={'Journey Date'}
                value={journeyState?.journey?.journey_date ? new Date(journeyState?.journey?.journey_date) : null}
                rightSection={<IconCalendar size={16} />}
                placeholder={'Select Journey Date'}
                error={journeyState?.journeyErrors?.journey_date}
                // minDate={new Date()}
                // maxDate={dayjs(new Date()).add(15, 'day').toDate()}
                setValue={e => {
                  journeyState.setJourney({
                    ...journeyState?.journey,
                    journey_date: e ? dayjs(e).format('YYYY-MM-DD') : null
                  })
                }}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <SelectComp
                isRequired
                label={'Aircraft'}
                placeholder={'Choose Aircraft'}
                data={aircraftQuery.data}
                value={journeyState?.journey?.aircraft_id}
                error={journeyState?.journeyErrors.aircraft_id}
                setValue={val => {
                  journeyState.setJourney({
                    ...journeyState?.journey,
                    aircraft_id: val
                  })
                }}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                readOnly
                variant='filled'
                label={'Sector Pattern'}
                value={sectorPattern}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <JourneyLegs />
            </Grid.Col>
            <Grid.Col span={6}>
            <ProductListing journeyDate ={journeyState?.journey?.journey_date} />
            </Grid.Col>
          </Grid>
        </Box>
        <Grid>
          <Grid.Col span={12}>
            <Group justify='flex-start' mt={'md'}>
              <Group>
                <Button
                  w={200}
                  leftSection={<IconCheck size={16} />}
                  onClick={handleSave}
                  loading={saveJourneyMutation.isLoading}
                >
                  Save
                </Button>
                <Button
                  w={200}
                  color='gray'
                  variant='light'
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Group>
            </Group>
          </Grid.Col>
        </Grid>
      </Flex>
    </Drawer>
  )
}

export default NewJourneyForm
