export const action_name = {
  user: {
    single_create: 'employee_single_create',
    bulk_upload: 'employee_bulk_upload',
    template: 'employee_template',
    export: 'employee_export',
    status_change: 'employee_status_change',
  },
  master: {
    users_single_create: 'users_single_create',
    users_bulk_upload: 'users_bulk_upload',
    users_template: 'users_template',
    users_export: 'users_export',
  },
  products: {
    single_create: 'products_single_create',
    bulk_upload: 'products_bulk_upload',
    template: 'products_template',
    export: 'products_export',
  },
  aircrafts: {
    single_create: 'aircrafts_single_create',
    bulk_upload: 'aircrafts_bulk_upload',
    template: 'aircrafts_template',
    export: 'aircrafts_export',
  },
  airports: {
    bulk_upload: 'airports_bulk_upload',
    template: 'airports_template',
    export: 'airports_export',
  },
  user_categories: {
    bulk_upload: 'user_categories_bulk_upload',
    template: 'user_categories_template',
    export: 'user_categories_export',
  },
  product_categories: {
    bulk_upload: 'product_categories_bulk_upload',
    template: 'product_categories_template',
    export: 'product_categories_export',
  },
  user_roles: {
    bulk_upload: 'user_roles_bulk_upload',
    template: 'user_roles_template',
    export: 'user_roles_export',
  },
  return_reasons: {
    bulk_upload: 'return_reasons_bulk_upload',
    template: 'return_reasons_template',
    export: 'return_reasons_export',
  },
  payment_exception_reasons: {
    bulk_upload: 'payment_exception_reasons_bulk_upload',
    template: 'payment_exception_reasons_template',
    export: 'payment_exception_reasons_export',
  }
}