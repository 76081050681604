import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { displayNotification } from "../../commonComponents/notifications/displayNotification";

import Body from "../../commonComponents/body/Body";
import { generateReportHeaders } from "../../utils/table.utils";
import { getAllData } from "../../services/common.service";
import { Box, Grid, Paper, Skeleton, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import * as Icons from "@tabler/icons-react";

const source = "reporting";
// let defaultSorting = { id: 'tail_reg_no', desc: false };
const filterData = (data) => {
  return data?.data;
};
const Reports = () => {
  const [pagination, setPagination] = useState({
    page: -1,
    search: "",
    source,
    filter: { is_active: true },
  });
  const navigate = useNavigate();

  const allRecordsQuery = useQuery({
    queryKey: ["all-records", source, pagination],
    queryFn: () => getAllData(pagination),
    select: filterData,
  });

  function onReportClick(item) {
    let url = `/reports/${item?.report_name
      ?.replace(/\s/g, "-")
      .toLowerCase()}`;
    url += `?name=${item?.api}`;
    return function () {
      navigate(url, {
        state: item,
      });
    };
  }

  if (allRecordsQuery.isLoading) {
    return (
      <Body>
        <Title>Reports</Title>
        <Grid mt={"md"}>
          {[...Array(5)].map((_, index) => (
            <Grid.Col span={2} key={index}>
              <Paper shadow="sm" p={"sm"}>
                <Skeleton height={36} width={36} circle mb="xs" />
                <Skeleton height={20} width="80%" mb="xs" />
                <Skeleton height={16} width="60%" />
              </Paper>
            </Grid.Col>
          ))}
        </Grid>
      </Body>
    );
  }

  return (
    <Body>
      <Title>Reports</Title>
      <Grid mt={"md"}>
        {allRecordsQuery?.data?.map((item) => {
          const ReportIcon = Icons[item?.icon || "IconChartDonut"];
          return (
            <Grid.Col span={2} key={item?.id}>
              <Paper
                shadow="sm"
                p={"sm"}
                style={{ cursor: "pointer" }}
                styles={{ root: { "&:hover": { backgroundColor: "red" } } }}
                onClick={onReportClick(item)}
              >
                <ReportIcon strokeWidth={1.25} size={36} color={"gray"} />
                <Text fw={500} size="md">
                  {item?.report_name}
                </Text>
                <Text c={"gray.6"} size="sm">
                  {item?.purpose}
                </Text>
              </Paper>
            </Grid.Col>
          );
        })}
      </Grid>
    </Body>
  );
};

export default Reports;
