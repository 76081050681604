import { getAPICall, patchAPICall, postAPICall } from "./apiCall"


export const getMasterDataByName = ({ tableName, page, search, fileType, filter }) => {
  let url = `master/${tableName}?page=${fileType ? -1 : page}`;
  if (search) url += `&search=${search}`;
  if (filter && Object.entries(filter)?.filter(([_, value]) => value)?.length) url += `&filters={${Object.entries(filter).map(([key, value]) => `"${key}_eq":${value}`).join(",")}}`;
  if (fileType) url += `&download_file_type=${fileType}`;
  return getAPICall(url, { returnObject: true })
}

export const getMasterDataById = ({ tableName, id }) => {
  let url = `master/${tableName}?filters={"id_eq":${id}}&fields=*.*`;
  return getAPICall(url, { returnObject: true })
}

export const createMasterDataByName = ({ tableName, body }) => {
  let url = `master/${tableName}`
  return postAPICall(url, { body })
}

export const updateMasterDataByName = ({ tableName, id, body }) => {
  let url = `master/${tableName}/${id}`
  return patchAPICall(url, { body })
}

export const getMasterDataHeadersByName = ({ tableName }) => {
  return getAPICall(`fields/${tableName}?action=read`)
}

export const getFormBuilderData = ({ tableName }) => {
  return getAPICall(`fields/${tableName}?action=write`)
}